.wrapper__border {
    border : 4px solid white;
    border-radius: var(--container-border-radius);
    margin: -4px;
}

.wrapper__border-top {
    border : 4px solid white;
    border-radius: var(--container-border-radius);
    margin: 0 -4px -4px -4px;
}

.wrapper__border-bottom-0 {
    border : 4px solid white;
    border-radius: var(--container-border-radius);
    border-bottom: 0;
    margin: -4px -4px 0 -4px;
}


.wrapper__border-left {
    border : 4px solid white;
    border-radius: var(--container-border-radius);
    margin : -4px -4px -4px 0;
}

.wrapper__border-nomargin {
    border : 4px solid white;
    border-radius: var(--container-border-radius);

}

.base__text {
    font-size: var(--base-font);
}

.menu__text {
    font-size: var(--menu-font);
}

.hide {
    display : none;
  }

.visible-flex {
    display : flex;
}

@media (max-width : 992px ){
    .t\:visible {
        display : block;
      }
      .t\:visible-flex {
        display : flex;
      }
      .t\:hide {
        display : none;
      }
}

@media (max-width : 640px ){
    .sm\:hide {
        display : none
    }
}
