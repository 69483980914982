.footer__outer-wrapper {
    display : flex;
    flex-direction: column;
}

.footer__main-wrapper {
    display : flex;
    gap : 10px;
    justify-content: space-between;
    align-items: center;
}

.footer__app-link-wrapper {
    padding : 1em 0;
    display: none;
    flex-direction: column;
    justify-content: start;
   /* align-items: center; */
    gap : 1em;
}

.play__button {
    width : 8em;
}

/*
.footer__menu-container {
    min-width: 50%;
    max-width: 75%;
}
    */

.footer__menu-wrapper {
    width : 100%;
    display : flex;
    gap : 4px;
    flex-wrap: wrap;
    justify-content: end;
}

.footer__copyright-wrapper {
    display : flex;
    justify-content: end;
}

.copyright__label {
    font-size: var(--font-400);
    font-weight: 400;
}

.copyright__label::before {
    content : "© "
}

.copyright__label::after {
    content : " All rights reserved."
}

.footer__menu-item {
    position : relative;
}

.footer__menu-item::after {
    content : "";
    position : absolute;
    left : 0;
    bottom : -2px;
    width : 100%;
    height : 1px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition : transform ease-in 250ms;
}

.footer__menu-item:hover::after {
    transform : scaleX(0.8);
}

.address-container {
    font-size : 1em;
}

.footer__top-wrapper {
  /*  display : flex;
    justify-content : space-between; */
}

@media (max-width : 990px) {
    .footer__menu-wrapper {
        justify-content: start;
    }
    

    .footer__top-wrapper {
     /*   flex-direction : column;
        justify-content : start; */
    }
    

}

@media (max-width : 640px ){
   /* .footer__main-wrapper {
        flex-direction: column;

    }
    
*/

.footer__main-wrapper {
    justify-content: center;
}
    .footer__app-link-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .4em;
    }

 /*   .footer__menu-container {
        min-width : 75%;
        max-width: 100%;
    }
 */
    .address-container {
        font-size : .7em;
    } 

   
}