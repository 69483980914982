.contact__outer-wrapper {
    background-color: var(--theme-primary-color);
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    width : 100%;
    min-height : 100em;
    border-radius : var(--container-border-radius);
    border-collapse: collapse;
}

.top__wrapper-contact {
    background-image: url('https://ik.imagekit.io/gunadev/Dental/contactgraphic.jpg?updatedAt=1722359794501');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
   
}

.content__wrapper-choose-us {
    background-color : #e1e9ed;
    border-radius : var(--container-border-radius);
    padding: 4em 0;
}

.fancy__contacttext {
    font-size: 10em;
    font-weight: 600;
  line-height: .75em;
}

.text-heading {
    font-size : 4em;
    font-weight: 600;
    line-height: .75em;
    text-align: center;
}

.form__container {
    display : flex;
    justify-content: space-between;
    align-items: center;
  
}

.field {
    padding : .9em;
    border : 1px solid #3b3b3b;
    background-color: transparent;
    border-radius: var(--theme-form-border-radius);
    color: white;
    outline: none;
    min-width : 200px;
    width: fit-content;
}

.field__container {
    position : relative;
    transition: 0.5s;
}

.field__label {
    position : absolute;
    left : 1em;
    top : -.6em;
    color: #b2b2b2;
    background-color: black;
    padding: 0 .4em;
    font-size: .9em;
    pointer-events: none;
    transition: 0.5s;
}


.field__container input:valid ~ span, .field__container input:focus ~ span {
    color : white;
    background-color: black;
}

.field__container input:valid , .field__container input:focus {
    border : 1px solid white;
}

.form__submit {
    width : 200px;
    cursor: pointer;
}

.about__content {
    color : #cdcdcd;
    font-size : 1.3em;
    padding : .5em 0;
}

.choose-us__container {
  /*  display : flex; */
  display : grid;
    flex-direction : column;
    align-items : center;
    justify-content : center;
    gap : 1em;
}

.doctors__container {
    display : flex ;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.doctors__profile-container {
    width : 150px;
    height : 150px;
    border-radius: 50%;
   background-size: cover;
   background-position: top center;
   background-repeat: no-repeat;
   background-color: #ffffff;
}

.doctors__wrapper {
    max-width: 1080px;
    margin-top: 80px;
}

.doctors__description {
    font-size: .8em;
    text-align: center;
    padding-top: 1em;
}

@media (max-width : 1024px ){
    .top__wrapper-contact {
        background-size: 70em;

    }

    .contact__outer-wrapper {
        min-height: 90em;
    }

    .fancy__contacttext {
        font-size: 8em;
    }

    .text-heading {
        font-size : 3em;
    }

    .about__content {
        font-size : 1em;
    }

    .form__container {
        flex-direction: column;
        gap : 1em;
        align-items: unset;
    }

    .field {
       
        min-width : 200px;
        width: 100%;
    }
}

@media (max-width : 992px ){
    .top__wrapper-contact {
        background-size: 60em;
  
    }

    .contact__outer-wrapper {
        min-height: 75em;
    }

}

@media (max-width : 640px ){

    .top__wrapper-contact {
        background-size: 40em;
    }

    .contact__outer-wrapper {
        min-height: 60em;
    }

    .fancy__contacttext {
        font-size: 6em;
    }

    .text-heading {
        font-size : 2em;

    }

    .about__content {
        font-size : .8em;
    }


}

