body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

:root {
  --container-padding-left : 1.5em;
  --container-padding-right : 1.5em;
  --container-border-radius : .9em;
  --theme-primary-btn-padding : .8em 1.4em;
  --theme-primary-btn-label-font : 1.3em;
  --theme-primary-btn-label-font-hover : 1.2em;
  --theme-form-border-radius : 5px;
  --theme-primary-btn-border-radius : 50px;
  --theme-primary-btn-bg : white;
  --theme-primary-btn-bg-hover : #d3d3d3;
  --theme-primary-btn-color : black;
  --theme-primary-color : #F5F6F8;
  --base-font : 1em;
  --menu-font : 1em;
  --font-600 : 1.5em;
  --font-400 : .9em;
}

.container__fit {
  width : 100%;
  padding-left : var(--container-padding-left);
  padding-right: var(--container-padding-right);
}

.wrapper {
  display : flex;
  justify-content: center;
  align-items: center;
}
.sub__heading {
  font-size: var(--font-600);
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}


.primary__btn {
  padding : var(--theme-primary-btn-padding);
  background-color: var(--theme-primary-btn-bg);
  border-radius: var(--theme-primary-btn-border-radius);
  font-size: var(--theme-primary-btn-label-font);
  color : var(--theme-primary-btn-color);
  transition: background-color ease-in 250ms, font-size ease-in 250ms;
}

.primary__btn:hover {
  background-color : var(--theme-primary-btn-bg-hover);
  font-size: var(--theme-primary-btn-label-font-hover);
}

.h-10\.5\% {
  height : 10.5%
}

.h-30\% {
  height : 30%
}

.h-39\% {
  height : 39%;
}

.h-71\% {
  height : 71%
}
.h-32\%{
  height : 32%
}
.h-33\.5\%{
  height : 33.5%
} 
.h-34\%{
  height : 34%
}

.h-35\%{
  height : 35%
} 


@media (max-width : 1025px) {
  .xl\:h-28\% {
    height : 28%
  }
  .xl\:h-33\.5\% {
    height : 33.5%
}
  .xl\:h-37\.5\% {
    height : 37.5%
}
}

@media (max-width : 992px ){
  :root {
    --theme-primary-btn-label-font : 1em;
    --font-400 : .8em;
    --menu-font : .8em;
  }
  
  .t\:h-30\% {
    height : 30%;
  }

  .t\:h-32\%{
    height : 32%
}

.t\:h-33\% {
    height : 33%
}

.t\:h-40\% {
    height : 40%
}
}

@media (max-width : 640px ){
  :root {
    --base-font : .8em;
    --menu-font : .7em;
    --font-600 : 1em;
    --font-400 : .7em;
    
    --container-padding-left : 1em;
  --container-padding-right : 1em;
  }

  .sm\:h-8\% {
    height : 8%
  }
  .sm\:h-79\% {
    height : 79%
  }
}

@media (max-width : 428px ){
  .xxs\:h-43\% {
    height : 43%;
}

.xxs\:h-30\% {
    height : 30%
}
}
