.gallery__outer-wrapper {
  background-color: white;
    width : 100%;
    height : 44em;
    border-radius : var(--container-border-radius);
    border-collapse: collapse;
}


.gallery__outer-wrapper > div .gallery__content-outer-wrapper {
  background-image: url('https://ik.imagekit.io/gunadev/Dental/gallerybanner.jpg?updatedAt=1722358544120');
  background-position: center;
  background-size: 90em;
  background-repeat: no-repeat;
}

.gallery__outer-wrapper > div:nth-of-type(1) .gallery__content-outer-wrapper {
  background-position: 0 -3.85em;;
} 

.gallery__outer-wrapper > div:nth-of-type(2) .gallery__content-outer-wrapper {
  background-position: 0 -35.675em;
}



.fancy__gallerytext {
    font-size: 6.5em;
    font-weight: 600;
  line-height: .75em;
}

.swiper {
  height : 100%
}

.swiper-slide {
  width : 200px !important;
  height : 100% ;
  border-radius: var(--container-border-radius);
  padding : .8em;
  background: rgba(255,255,255,0.2);
  backdrop-filter: blur(10px);
}

.swiper-slide-active {
  background-color: white;
}

.card__title-wrapper {
  inline-size: 180px;
  overflow-wrap: break-word;
}



.card__counter {
  position : relative;
  color : white;
  font-size: .8em;
}

.card__counter::before {
  content : '0'
}

.card__title {
  font-size: 2em;
  color : white;
  line-height: .7em;
}

.card__sub-text {
  margin-top : .8em;
  inline-size: 180px;
  overflow-wrap: break-word;
  font-size : 1em;
  color : #ececec;
}

.swiper-slide-active:has(.card__title) .card__title {
  color : black
}

.swiper-slide-active:has(.card__sub-text) .card__sub-text {
  color : #686767
}

.swiper-slide-active:has(.card__counter) .card__counter {
  color : black;
}

.card__counter::after {
  content : '';
  height : 30px;
  width : 30px;
  border : 1px solid white;
  border-radius: 50%;
  position : absolute;
  top : -20%;
  right : -50%;
}

.swiper-slide-active:has(.card__counter) .card__counter::after {
  border-color: black;
}

.h-51\% {
  height : 51%
}

.h-100\% {
  height : 100%
}


.resp__text-color {
  color : white
}



@media (max-width : 1248px ){
  .fancy__gallerytext {
    font-size: 4.5em;
    font-weight: 600;
  line-height: .75em;
}
}

@media (max-width : 1024px ){
  .gallery__outer-wrapper > div .gallery__content-outer-wrapper {
    background-size: 80em;
    background-repeat: no-repeat;
  }
  
  .gallery__outer-wrapper > div:nth-of-type(1) .gallery__content-outer-wrapper {
    background-position: -10em -3.85em;;
  }
  
  .gallery__outer-wrapper > div:nth-of-type(2) .gallery__content-outer-wrapper {
    background-position: -10em -35.675em;
  }


  
}

@media (min-width : 1024px ){
  .swiper-slide {
    width : 280px !important
  }
}

@media (max-width : 992px ){

  .gallery__outer-wrapper > div .gallery__content-outer-wrapper {
    background-size: 80em;
    background-repeat: no-repeat;
  }
  
  .gallery__outer-wrapper > div:nth-of-type(1) .gallery__content-outer-wrapper {
    background-position: -20em -3.85em;;
  }
  
  .gallery__outer-wrapper > div:nth-of-type(2) .gallery__content-outer-wrapper {
    background-position: -20em -35.675em;
  }

  .gallery__wrapper-right{
    display : none;
  }

  .gallery__wrapper-left-2{
    display : none;
  }
  .t\:h-102\% {
    height : 102%
  }

  .t\:h-content {
    height : fit-content
  }

  .resp__text-color {
    color : black
  }
  
}

@media (max-width : 640px ){
  .gallery__outer-wrapper {
    height : 50em;
}

.gallery__outer-wrapper > div .gallery__content-outer-wrapper {
  background-size: 80em;
  background-repeat: no-repeat;
}

.gallery__outer-wrapper > div:nth-of-type(1) .gallery__content-outer-wrapper {
  background-position: -30em -3.85em;;
}

.gallery__outer-wrapper > div:nth-of-type(2) .gallery__content-outer-wrapper {
  background-position: -30em -35.675em;
}

.fancy__gallerytext {
  font-size: 3.2em;
 margin-top : 1em;
}

}

@media (max-width : 428px ){

  .gallery__outer-wrapper {
    height : 40em;
  }

  .gallery__outer-wrapper > div .gallery__content-outer-wrapper {
    background-size: 80em;
    background-repeat: no-repeat;
  }
  
  .gallery__outer-wrapper > div:nth-of-type(1) .gallery__content-outer-wrapper {
    background-position: -30em -3.85em;;
  }
  
  .gallery__outer-wrapper > div:nth-of-type(2) .gallery__content-outer-wrapper {
    background-position: -30em -35.675em;
  }
 
  .fancy__gallerytext {
    font-size: 3.5em;
   margin-top : 1em;
  }
  .xs\:gallery-col {
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
    text-align: center;
  }

  .resp__text-color {
    color : black
  }
 
}