.service__outer-wrapper {
    background-color: white;
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    width : 100%;
    height : 44em;
    border-radius : var(--container-border-radius);
    border-collapse: collapse;
}

.fancy__servicetext {
    font-size: 6em;
    font-weight: 600;
  line-height: .75em;
}

.image__wrapper-left {
    background-image: url('https://ik.imagekit.io/gunadev/Dental/teethgraphics.jpg?updatedAt=1722358543803');
    background-size : 28em;
    background-position: top;
    background-repeat: no-repeat;
}

.image__wrapper-right {
    background-image: url('https://ik.imagekit.io/gunadev/Dental/teethgraphics.jpg?updatedAt=1722358543803');
    background-size : 35em;
    background-position: bottom;
    background-repeat: no-repeat;
}

.sub__heading {
    line-height: .4em;
}
 
.sub__tagline {
    color : #686767;
    font-size : .9em;
    line-height: .9em;
    padding-top : .5em;
}


.sub__heading-service-wrapper-400{
    inline-size: 20px;
   
}

.outer__wrapper-right {
    background-image: url('https://ik.imagekit.io/gunadev/Dental/servicegraphic.jpg?updatedAt=1722358544078');
    background-size: 80em;
    background-position: top;
    background-repeat: no-repeat;
}

.left__top-service-wrapper {
    background-color : var(--theme-primary-color);
}

.left__bottom-service-wrapper {
    background-color: #E1E9ED;
}

.sub__heading-service-wrapper-600 {
    inline-size: 10em;
    overflow-wrap: break-word;
}

.service-swiper-slide {
  width : 300px !important;
  height : 100% ;
  border-radius: var(--container-border-radius);
  background: rgba(255,255,255,0.2);
  backdrop-filter: blur(10px);
  padding : 0;
}

.service__card-wrapper {
    background-color : rgb(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    width : 100%;
    border-radius: 10px;
    color : black;
}

/* .service__card-wrapper[data-key="0"] {
    background-color: white;
    color:black;
}
    */

.card__service-arrow {
    font-size: .8em;
    color : white;
    position : relative
}

.service__card-wrapper[data-key="0"]:has(.card__service-arrow) .card__service-arrow {
    color : black
}

.card__service-arrow::before {
    content: '0';
}

.card__service-arrow::after {
    content : '';
    padding : .9em .9em;
    position : absolute;
    top : -2px;
    left:-25%;
    border : 1px solid white;
    border-radius: 50px;
}

.service__card-wrapper[data-key="0"]:has(.card__service-arrow) .card__service-arrow::after {
    border : 1px solid black;
}



@media (max-width : 1025px ){
    .image__wrapper-left {
        background-size: 50em;
    }
    .image__wrapper-right{
        background-size: 47em;
    }
   
}


@media (max-width : 992px ){

    .service__outer-wrapper {
        height : 48em;
    }

    .image__wrapper-left{
        background-size : 38em;
      
    }

    .sub__heading-service-wrapper-400{
        inline-size: 150px;
       
    }

    .service__card-wrapper {
     /*   background-color : rgb(0, 0, 0, 0.2); */
     background-color : #ffffff;
  
    }

    
    .sub__heading {
        line-height: .2em;
    }
}

@media (max-width : 640px ){
    .fancy__servicetext {
        font-size: 5em;
        font-weight: 600;
      line-height: .75em;
    }
}

@media (max-width : 428px ) {
    .service__outer-wrapper {
        height : 50em;
        
    }

    .card__service-arrow {
        font-size: .8em;
    }



    .fancy__servicetext {
        font-size: 4em;
    }

    .sub__heading-service-wrapper-600{
        inline-size: 7em;
    }

    

}